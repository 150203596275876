import type { LocaleId } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { Partner } from 'types/partners/partner';

export function getLocalisedPartners(partners: Partner[], currentLocaleId: LocaleId): Partner[] {
  return partners?.filter(entry => {
    if (!entry.hideOnLocales) return entry;

    return !entry.hideOnLocales.find(locale => locale === currentLocaleId);
  });
}

import styled, { css } from 'styled-components';

import { backgroundAnimationMixin } from 'styled/helpers/backgroundAnimationMixin';

import { Container } from '../Container.styled';

interface AnimatedContainerProps {
  animatedBackground?: 'default' | 'home' | 'supportedMarkets';
}

const defaultBackgroundMixin = ({ animatedBackground }: AnimatedContainerProps) => {
  if (animatedBackground === 'home') {
    return css`
      background: radial-gradient(
        circle at 50% 50%,
        rgba(255, 214, 102, 0.5),
        rgb(254, 173, 129, 0.5),
        rgba(246, 167, 162, 0.5),
        rgba(147, 156, 235, 0.5)
      );
    `;
  }

  if (animatedBackground === 'supportedMarkets') {
    return css`
      background: linear-gradient(272.22deg, #202dac 5.86%, #feceb3 44.61%, #fc5c03 100.31%);
    `;
  }

  return css`
    background: radial-gradient(circle at 50% 50%, #ffd666, #fead81, #f6a7a2, #939ceb);
  `;
};

export const AnimatedContainer = styled(Container)<AnimatedContainerProps>`
  ${defaultBackgroundMixin}
  ${backgroundAnimationMixin}
`;
